const BASE_URL= 'https://nest.relie.one/';
 









//*********************          DriverGetAll start            ***************** */ 

export const driverGetAll = async (token: string) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			'x-access-token': token,
		},
	};
debugger
	try {
		const response = await fetch(`${BASE_URL}drivers/getall?page_size=100&page_number=1`,options,);
		const data = await response.json();
		console.log(response,'===>>>>>>');
		return data.data.reverse();
	} catch (error) {
		console.log('error', error);
	}
};

// DriverGetAll end














//*****************************************DriverADD start********************************** */ 

export const driverAdd = async (token: string, data: any) => {
	const formData: any = new FormData();
	formData.append('avatar', data.avatar);
	formData.append('is_activated', data.is_activated);
	formData.append('fullname', data.fullname);
	formData.append('email', data.email);
	formData.append('password', data.password);
	formData.append('dob', data.dob);
	formData.append('gender', data.gender);
	formData.append('contact_number', data.contact_number);
	formData.append('alter_contact_number', data.alter_contact_number);
	formData.append('note', data.note);
	formData.append('admin_note', data.admin_note);
	formData.append('admin_note', data.admin_note);
	formData.append('bank_details', JSON.stringify(data.bank_details));
	formData.append('driver_criteria', JSON.stringify(data.driver_criteria));

	formData.append('vehicle_details', JSON.stringify(data.vehicle_details));
	formData.append('location', JSON.stringify(data.location));
	formData.append('adharcard_image', data.adharcardImage);
	formData.append('liscense_image', data.liscenseImage);
	formData.append('rc_image', data.rcImage);
	formData.append('verified_picture', data.verifiedPicture);
	


	console.log(JSON.stringify(formData));

	debugger;
	const options = {
		method: 'POST',
		body: formData,
	};

	try {
		const response = await fetch(`${BASE_URL}drivers/create`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};
// driverAdd end






//*****************************************// driver edit********************************** */ 

export const driverEdit = async (token: string, data: any,id:any) => {
	// const formData: any = new FormData();
	// formData.append('avatar', data.avatar);
	// formData.append('is_activated', data.is_activated);
	// formData.append('fullname', data.fullname);
	// formData.append('email', data.email);
	// formData.append('password', data.password);
	// formData.append('dob', data.dob);
	// formData.append('gender', data.gender);
	// formData.append('contact_number', data.contact_number);
	// formData.append('alter_contact_number', data.alter_contact_number);
	// formData.append('note', data.note);
	// formData.append('admin_note', data.admin_note);
	// formData.append('admin_note', data.admin_note);
	// formData.append('bank_details', JSON.stringify(data.bank_details));
	// formData.append('vehicle_details', JSON.stringify(data.vehicle_details));
	// formData.append('location', JSON.stringify(data.location));
	// formData.append('adharcard_image', data.adharcard_image);
	// formData.append('liscense_image', data.liscense_image);
	// formData.append('rc_image', data.rc_image);
	// formData.append('verified_picture', data.verified_picture);

	// console.log(JSON.stringify(formData));

	debugger;
	console.log(data)
	const options = {
		method: 'PUT',
		body: JSON.stringify(data),
	};

	try {
		const response = await fetch(`${BASE_URL}drivers/updateDriver/${id}`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};



export const driverUpdateimages=async(data:any,id:any)=>{

	const formData: any = new FormData();

     formData.append('adharcard_image', data.adharcard_image);
	formData.append('liscense_image', data.liscense_image);
	formData.append('rc_image', data.rc_image);
	formData.append('verified_picture', data.verified_picture);

	const options={
		method: 'PUT',
		body: formData
	}

	try {
		const response=await fetch(`${BASE_URL}drivers/uploadProfiley/${id}`,options)
		return response

	} catch (error) {
		
	}
}
// driver end







//*****************************driver get by id start************************************ */ 
export const driverViewid = async (token: string, id: string) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			'x-access-token': token,
		},
	};

	try {
		const response = await fetch(`${BASE_URL}drivers/getById/${id}`, options);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error);
	}
};

// drivver get by is end














// ********************************************************Driver deleter**************************
export const Driverdelete = async (id: any) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}drivers/deleteDriver/${id}`, options);
		const data = await response.json();
		return data;
	} catch (error) {
		console.log(error);
	}
};

// *******************************search api*******************************//

export const  DriverSearch=async(name:any)=>{
	const options={
		method:'GET',
		headers:{
			'Content-Type':'application/json',
		},
		
	}

	try {
			const response=await fetch(`${BASE_URL}drivers/search?searchValue=${name}`,options)
			const data=await response.json()
			console.log(data.data)
			return data.data
	} catch (error) {
		console.log(error)
	}
}
// *******************************filter api*******************************//

export const  DriverFilter=async(one:any )=>{
	const options={
		method:'GET',
		headers:{
			'Content-Type':'application/json',
		},
		
	}

	try {
			const response=await fetch(`${BASE_URL}drivers/filter?is_active=${one.is_active}&is_inactive=${one.is_inactive}${one.ascending}`,options)
			const data=await response.json()
			console.log(data.data)
			return data.data
	} catch (error) {
		console.log(error)
	}
}